const _lang_my_orders = {
  payment_ids: {
    "0": "Invalid",
    "1": "PIX",
    "2": "PIX",
    "3": "STRIPE",
    "4": "MERCADO PAGO",
    "5": "CREDIT CARD",
  },
  payment_status: {
    "completed": "Completed",
    "pending":   "Pending",
    "cancelled": "Cancelled",
    "on-hold":   "On Hold",
    "failed":    "Failed",
  },

  timer_expired: "Payment time has expired",
  order_cancelled: "This order is cancelled",
  order_completed: "Payment completed",

  section_pretitle: "Store",
  section_title: "My Orders",

  table_header_title_date: "Date",
  table_header_title_order: "Order",
  table_header_title_status: "Status",
  table_header_title_total: "TOTAL",
  table_header_title_payment_methods: "Payment Method",
}

module.exports = { _lang_my_orders };
