import { useState, useContext, useEffect }        from "react";
import { useNavigate  }                           from "react-router-dom";

import LoadingComponent                           from "../../Components/Common/LoadingComponent";
import PreviewProductTable                        from "../../Components/PreviewProductTable/PreviewProductTable";
import CreateSvg                                  from "../../Components/Common/CreateSvg";

import Context                                    from "../../Provider/Context";
import SlideNewProducts                           from "../../Components/SlideNewProducts/SlideNewProducts";

import IconeNewPt                                 from "../../assets/img/icons/novoui.png";
import IconeNewEng                                from "../../assets/img/icons/newui.png";

/* IMPORT LANGUAGE */
import { _lang_ui, _lang_base }                    from "../../Languages/languages";

function UI() {
  const { UserInfoBase, Products, UserConfigBase } = useContext(Context);
  const UserInfo                                   = UserInfoBase.Value;
  const UserConfig                                 = UserConfigBase.Value;

  const history                                    = useNavigate();
  const product_info                               = [...Products.vip, ...Products.free].filter((item) => (item.product_category_id === 1 && item.product_enable_ui === 1))

  const [filter_name, set_filter_name]             = useState("");
  const [category_product, set_category_product]   = useState(UserConfig.category_product_ui);
  const [card_table, set_card_table]               = useState(0);
  const [pos_card_table, set_pos_card_table]       = useState({top: "500px",left: "200px"});
  const [loading_products, set_loading_products]   = useState(true);

  const mouse_card_fora                           = () => 
    document.querySelector(".cd-single-point").classList.remove('is-open');

  const mouse_card_cima                           = (elemento) => {
    const screen_size              = { width: window.innerWidth, height: window.innerHeight };
    if(screen_size.width <= 882) return;
    const { dataset, offsetWidth } = elemento.target;
    const { top, left }            = elemento.currentTarget.getBoundingClientRect();
    const distance_left            = left + offsetWidth + 5;
    const isLeft                   = distance_left + 340 >= screen_size.width;
    
    if (top - 100 <= 0 || top + 100 >= screen_size.height) return;
    set_card_table(dataset.mod);

    const selectedPoint = document.querySelector(".cd-single-point");
    const selectInfo    = document.querySelector(".cd-more-info");
  
    if (!selectedPoint || !selectInfo) return;
  
    selectInfo.classList.remove('cd-left', 'cd-right');
    selectInfo.classList.add(isLeft ? 'cd-left' : 'cd-right');
    set_pos_card_table({ top: `${top + window.scrollY}px`, left: `${isLeft ? left - 278 : distance_left}px` });
    selectedPoint.classList.add('is-open');
  };

  const category_list                             = [
    {
      title: _lang_ui.category_list.all_products,
      value: "0",
    },
    {
      title: _lang_ui.category_list.favorites_products,
      value: "1",
    },
    {
      title: _lang_ui.category_list.recenty_products,
      value: "2",
    },
    {
      title: _lang_ui.category_list.mods_vip,
      value: "3",
    },
    {
      title: _lang_ui.category_list.mods_free,
      value: "4",
    },
    {
      title: _lang_ui.category_list.my_mods,
      value: "5",
    },
  ]

  let filter_products = product_info.filter((item) => {
    if (item.product_information === undefined || item.product_information.product_title === undefined) return false;
    const sub_names         = (item.product_sub_names !== null && item.product_sub_names !== undefined) ? item.product_sub_names : "";
    const filter_name_lower = filter_name.toLowerCase();
    const matches_title     = item.product_information.product_title.toLowerCase().includes(filter_name_lower);
    const matches_subName   = sub_names.includes(filter_name_lower)
    return matches_title || matches_subName;
  });

  switch(category_product) {
    case "1":
      const my_favorites = (UserInfo !== null && UserInfo !== undefined && UserConfig.products_favorites !== undefined) ?  
        filter_products.filter((item) => (UserInfo) ? UserConfig.products_favorites.includes(item.product_id) : item) : [];
        filter_products = my_favorites;
      break;
    case "2":
      filter_products = filter_products.slice().sort((a, b) => new Date(b.product_date) - new Date(a.product_date));
      break;
    case "3":
      filter_products = filter_products.filter((item) => (Number(item.product_type_id) === 2));
      break;
    case "4":
      filter_products = filter_products.filter((item) => (Number(item.product_type_id) === 1));
      break;
    case "5":
      const my_products = (UserInfo !== null && UserInfo !== undefined) ?  UserInfo.product_vip.map((item) => item.product_id) : [];
      filter_products = filter_products.filter((item) => my_products.includes(item.product_id));
      break;
    default:
      break;
  }

  useEffect(() => { setTimeout(() => set_loading_products(false) , 1000); }, []);
  
  const component_gen_filter                      = category_list.map((item, index) => 
    <option key={`catgory_list_${index}`} value={item.value}>{item.title}</option>);
  
  const component_loading_product_list            = filter_products.map((_item, index) =>
    <div key={`loading_${index}`} style={{display: (loading_products) ? "block" : "none"}} className="d-steam-card-wrapper">
      <div className="d-steam-card-v2">
        <LoadingComponent />
      </div>
    </div>
  )
  
  const component_new_product = (product) => {
    const product_date         = new Date(product.product_date);
    const current_date         = new Date();
    const difference_inms      = current_date - product_date;
    const difference_in_days   = difference_inms / (1000 * 60 * 60 * 24);
    if (difference_in_days < 30) return (<div className="emblema-new-ui-page">
      <img src={(_lang_base.lang_base === "pt_BR") ? IconeNewPt :IconeNewEng} alt={_lang_base.web_site_name} />
    </div>)
  }

  const gen_products_list                         = filter_products.map((item, index) =>
    <div style={{display: (loading_products) ? "none" : "block"}} className="d-steam-card-wrapper" key={index} onClick={() => history(`${item.product_slug}`)}>
      <div 
        onMouseOver={mouse_card_cima}
        onMouseOut={mouse_card_fora}
        className="d-steam-card anim"
        style={{"backgroundImage": `url('${item.product_base_ui_img}')`,"--delay:": ".4s;"}} 
        data-mod={item.product_id}
      >
        {component_new_product(item)}
      </div>
    </div>
  )
  
  return(
    <>
      <div className="container profile"> 
        <div className="content-grid">
          <div className="section-banner-store"><SlideNewProducts /></div>
          <div className="section-filters-bar section-filters-bar-v2 section-filters-bar-no-shadow v1">

            <div className="section-filters-bar-actions section-filters-bar-actions-v2">
              <div className="form form-v2">
                <div className="form-input small with-button">
                  <input placeholder={_lang_ui.input_scan_mods} type="text"
                    value={filter_name} 
                    onChange={({target}) => set_filter_name(target.value.toLowerCase())}
                  />
                </div>
              </div>
              <div className="form-select form-select-v3 small">
                <select value={category_product} 
                  id="items-filter-category" 
                  name="items_filter_category" 
                  onChange={({ target }) => {
                    set_category_product(target.value);
                    UserConfigBase.Update({ category_product_ui: target.value });
                  }}
                >
                  {component_gen_filter}
                </select>
                  <CreateSvg svgname="svg_small_arrow" svgclassname="form-select-icon form-select-icon-v2 icon-small-arrow" />
              </div>
              {category_list.map((item, index) => (item.value === category_product) && <div key={`${index}-category`} className="category-title-container last-div"><h5 className="category-title">{item.title}</h5></div> )}
            </div>
          </div>
          <div className="grid grid-remove grid-5-5-5 centered">{component_loading_product_list}{gen_products_list}</div>
        </div>
      </div>
      <div className="cd-single-point modal-game" style={pos_card_table}>
        <PreviewProductTable product_id={ card_table } product_info={product_info} />
      </div>
    </>
  )
}

export default UI;
