import CreateSvg                                      from "../../Components/Common/CreateSvg";

function HeaderHubProfile({ hub_components, page_id, set_page_id, header_id, set_header_id }) {
  const component_sidebar_menu_item = hub_components.map((item) => 
    <div class="sidebar-menu-item">
      <div class="sidebar-menu-header accordion-trigger-linked" onClick={() => set_header_id(item.sidebar_menu_header_id)}>
          {item.sidebar_menu_header_icon}
        <div class="sidebar-menu-header-control-icon">
          {
            (item.sidebar_menu_header_id === header_id) ? 
            <CreateSvg svgname="svg_minus_small" svgclassname="sidebar-menu-header-control-icon-closed icon-minus-small" /> :
            <CreateSvg svgname="svg_plus_small" svgclassname="sidebar-menu-header-control-icon-closed icon-plus-small" />
          }
        </div>
        <p class="sidebar-menu-header-title">{item.sidebar_menu_header_title}</p>
        <p class="sidebar-menu-header-text">{item.sidebar_menu_header_text}</p>
      </div>
      {
        (item.sidebar_menu_header_id === header_id) && 
        <div style={{overflow: "hidden", height: "100%", transition: "all 0.3s ease-in-out 0s"}}>
          <div className="sidebar-menu-body accordion-content-linked accordion-open">
            {item.sidebar_menu_body.map((sidebar_item) => 
              <div onClick={() => set_page_id(sidebar_item.sidebar_menu_link_id)} class={`sidebar-menu-link sidebar-two ${(page_id === sidebar_item.sidebar_menu_link_id) && "active"}`}>{sidebar_item.sidebar_menu_link}</div>
            )}
          </div>
        </div>
      }
    </div>
  );

  return (
    <div className="account-hub-sidebar">
      <div className="sidebar-box no-padding">
        <div className="sidebar-menu round-borders">
          {component_sidebar_menu_item}
        </div>
      </div>
    </div>
  )
}

export default HeaderHubProfile;
