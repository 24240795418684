import axios from 'axios';

const HEADERS_CACHE = {
  'Content-Type':   'application/x-www-form-urlencoded',
  'Cache-Control':  'no-cache, no-store, must-revalidate',
  'Pragma':         'no-cache',
  'Expires':        '0'
}

const HEADERS_NO_CACHE = { 'Content-Type':   'application/x-www-form-urlencoded' }

export const AUTH = axios.create({
  baseURL: process.env.REACT_APP_API_KEY_SYSTEM,
  headers: process.env.REACT_APP_LOCAL === "producao" ? HEADERS_NO_CACHE : HEADERS_NO_CACHE,
});

export const AUTH_STORE = axios.create({
  baseURL: process.env.REACT_APP_API_KEY_STORE,
  headers: process.env.REACT_APP_LOCAL === "producao" ? HEADERS_NO_CACHE : HEADERS_NO_CACHE,
});
