const _lang_checkout_pay = {
  nao_logado:     "Você não está logado",
  contactar_administrador: "Contate um administrador",
  efetue_o_pagamento: "Efetue o pagamento",
  btn_loja: "Loja",
  descricao_vazio: "Carrinho está vazio",

  header_pedido: "Pedido",
  data: "Data",

  formas_de_pagamento: {
    pix: {
      como_pagar: "Como pagar com Pix:",
      info_um: "Acesse o app ou site do seu banco",
      info_dois: "Busque a opção de pagar com Pix",
      info_tres: "Leia o QR code ou código Pix",
      infor_quatro: "Pronto! Você verá a confirmação do pagamento",
      esquenei_o_qr: "Escaneie o QR code:",
      codigo_valido: "Código válido por 60 minutos",
      se_preferir: "Se preferir, você pode pagar copiando e colando o seguinte código",
      btn_pagamento: "Copiar código",
      msg_codigo_copiado: "Código pix copiado",
    },
    mercado_pago: {
      como_pagar: "Como pagar com Mercado Pago:",
      info_um: "Acesse o link clicando no botão",
      info_dois: "Preencha com seus dados",
      info_tres: "Efetue o pagamento",
      btn_pagamento: "Abrir link de pagamento Mercado Pago",
      btn_copiar: "Copiar link de pagamento Mercado Pago",
      msg_codigo_copiado: "Link de pagamento copiado",
    },
    stripe: {
      como_pagar: "Como pagar com Stripe:",
      info_um: "Acesse o link clicando no botão",
      info_dois: "Preencha com seus dados",
      info_tres: "Efetue o pagamento",
      btn_pagamento: "Abrir link de pagamento Stripe",
      btn_copiar: "Copiar link de pagamento Stripe",
      msg_codigo_copiado: "Link de pagamento copiado",
    },
  },
  valor_a_pagar: "Valor a pagar: ",
  tempo_restante: "Tempo restante para pagamento:",
  btn_cancell_order: "Cancelar Pedido",
}

module.exports = { _lang_checkout_pay };
