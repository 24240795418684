/* IMPORT LANGUAGE */
import { _lang_preview_product_table, _lang_base }          from "../../Languages/languages";
import icon_vip                                             from "../../assets/img/icons/ui/vip.png";

function PreviewProductTable(props) {
  const product_info            = props.product_info.find(item => parseInt(item.product_id) === parseInt(props.product_id));
  if (!product_info) return;

  const product_pre_imgs = product_info.product_imgs.map((item, index) => 
    <div key={index} className="screenshot" style={{ backgroundImage: `url(${item})` }}></div>
  )

  return (
    <div className="cd-more-info" >
      <h4 className="hover_title">{product_info.product_information.product_title}</h4>
      <div className="hover_release">{_lang_preview_product_table.type}
        <span className={(product_info.product_type_id === 1) ? "free" : "vip"}>
          <span className="status_text"> {(product_info.product_type_id === 2) && <img src={icon_vip} alt={_lang_base.web_site_name} />} {(product_info.product_type_id === 1) ? "free" : "vip"}</span>
        </span>
      <div>
      <div className="hover_screenshots">{product_pre_imgs}</div>
      <div className="hover_body">
        <div className={"hover_review_summary " + product_info.product_information.product_status}>
          <div className="title">{_lang_preview_product_table.status} <span className="status_text">{product_info.product_information.product_status}</span></div>
        </div>
        <div style={ {clear: "left"}}></div>
      </div>

      <div className="hover_body">{_lang_preview_product_table.platform}
        <div className="hover_tag_row">
          <div className="app_tag">{product_info.product_platform}</div>
        </div>
      </div></div></div>
    </div>
  )
}

export default PreviewProductTable;
