const _lang_ui       = {
  category_list: {
    all_products: "Todos",
    favorites_products: "Favoritos",
    recenty_products: "Recentes",
    mods_vip: "Mods Vip",
    mods_free: "Mods Free",
    my_mods: "Meus Mods",
  },
  input_scan_mods: "Procurar Mods",
}

module.exports = { _lang_ui };
