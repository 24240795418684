const _lang_hub = {
  section_banner_title: "Account Hub",
  section_banner_text: "Profile information, notifications, settings, and much more!",
  hub_components: {
    profile: {
      sidebar_menu_header_title: "My Profile",
      sidebar_menu_header_text: "Change your avatar, cover, read notifications, and much more!",
      sidebar_menu_body: {
        profile_info: {
          sidebar_menu_link: "Profile Information",
        }
      }
    },
    store: {
      sidebar_menu_header_title: "Store",
      sidebar_menu_header_text: "Review your account, manage product verification stats, and much more!",
      sidebar_menu_body: {
        my_orders: {
          sidebar_menu_link: "My Orders",
        }
      }
    },
  }
}

module.exports = { _lang_hub };
