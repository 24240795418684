const _lang_my_orders    = {
  payment_ids: {
    "0": "Inválido",
    "1": "PIX",
    "2": "PIX",
    "3": "STRIPE",
    "4": "MERCADO PAGO",
    "5": "CARTÃO DE CRÉDITO",
  },
  payment_status: {
    "completed": "Completo",
    "pending":   "Pendente",
    "cancelled": "Cancelado",
    "on-hold":   "Aguardando",
    "failed":    "Fracassado",
  },

  timer_expired: "O tempo de pagamento expirou",
  order_cancelled: "Esse pedido está cancelado",
  order_completed: "Pagamento concluído",

  section_pretitle: "Loja",
  section_title: "Meus Pedidos",

  table_header_title_date: "Data",
  table_header_title_order: "Pedido",
  table_header_title_status: "Status",
  table_header_title_total: "TOTAL",
  table_header_title_payment_methods: "Método de Pagamento",

}

module.exports = { _lang_my_orders };
