import React, { useState, useEffect } from "react";
import { useNavigate  }               from "react-router-dom";

/* IMPORT IMAGENS */
import logo_gif                       from "../../assets/img/logo/logo.png";

/* IMPORT LANGUAGE */
import {  _lang_footer, _lang_base }  from "../../Languages/languages";

const Footer = () => {
  const history                 = useNavigate();
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const windowHeight     = window.innerHeight;
      const scrollTop        = document.documentElement.scrollTop;
      const scrollHeight     = document.documentElement.scrollHeight - windowHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 100;

      if (scrollPercentage > 90) setIsBottom(true);
      else setIsBottom(false);

    }
    window.addEventListener('scroll', handleScroll);
    return () => {window.removeEventListener('scroll', handleScroll);};
  }, []);

  return(
    <>
      <section className="brand-area"></section>
      <footer className={(isBottom) ? "footer-style-two has-footer-animation active-footer" : "footer-style-two has-footer-animation" }>
        <div className="footer__two-widgets">
          <div className="container custom-container">
            <div className="row">

              <div className="col-md-4 col-sm-7 order-1 order-md-0">
                <div className="footer-el-widget">
                </div>
              </div>

              <div className="col-md-4 col-sm-5 order-0 order-md-2">
                <div className="footer-el-widget text-start text-md-center widget_nav_menu">
                  <div className="footer-el-logo mb-35">
                    <div onClick={()=> history('/')}><img src={logo_gif} alt={_lang_base.web_site_name} /></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="copyright__wrap -style-two">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright__text text-center text-lg-start">
                  <p>Copyright © 2024 - All Rights Reserved By <span>{_lang_base.web_site_name}</span></p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="copyright__menu">
                  <ul className="list-wrap d-flex flex-wrap justify-content-center justify-content-lg-end">
                    <li>
                      <div onClick={()=> history('/refund-policy')}>{_lang_footer.footer_btns.politica}</div>
                    </li>
                    <li>
                      <div onClick={()=> history('/terms-of-use')}>{_lang_footer.footer_btns.termos_de_uso}</div>
                    </li>
                    <li>
                      <div onClick={()=> history('/privacy-terms')}>{_lang_footer.footer_btns.termos_privacidade}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;
