import { useEffect, useState, useContext }              from "react";
import { useNavigate, useLocation   }                   from "react-router-dom";

import Backdrop                                         from '@mui/material/Backdrop';
import CircularProgress                                 from '@mui/material/CircularProgress';

import Badge                                            from "@mui/material/Badge";
import { styled }                                       from "@mui/material/styles";
import IconButton                                       from "@mui/material/IconButton";
import ShoppingCartIcon                                 from "@mui/icons-material/ShoppingCart";
import TranslateIcon                                    from '@mui/icons-material/Translate';
import NotificationsIcon                                from "@mui/icons-material/Notifications";

import FormGroup                                        from '@mui/material/FormGroup';
import FormControlLabel                                 from '@mui/material/FormControlLabel';
import Switch                                           from '@mui/material/Switch';

import Hallowen                                         from "../Events/Hallowen";
import PopUpLive                                        from "../../Components/PopUpLive/PopUpLive";
import { SOCKET_ANALYTICS }                             from "../../Socket/Conexao";

import { product_list_function, 
  product_free_informations,
  product_vip_informations }                            from "../../Utils/DesignerFunction";
import Menus                                            from "./Menus";
import { EXIT_ACCOUNT, check_date_popup }               from "../../Utils/UserFunction";

import Context                                          from "../../Provider/Context";
import { useSynchronizeAccount }                        from "../../Hooks/SynconizeAccount";

/* IMPORT IMAGENS */
import logo_gif                                         from "../../assets/img/logo/logo.png";

/* IMPORT LANGUAGE */
import { _lang_base, _lang_header_top_layout }          from "../../Languages/languages";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

function HeaderTopLayout() {
  const history                                       = useNavigate();
  const location                                      = useLocation();
  const current_page                                  = location.pathname;

  const { UserInfoBase, 
    ProductsCart, 
    SocialLinks,
    Products,
    UserNotifications,
    PopUps,
    UserConfigBase }                                  = useContext(Context);

  const UserConfig                                    = UserConfigBase.Value;
  const UserInfo                                      = UserInfoBase.Value;
  const PopUpsInfo                                    = PopUps.Value;
  const products_cart                                 = ProductsCart.Value;
  const products_info                                 = [...Products.free, ...Products.vip];

  const user_notifications                            = UserNotifications.Value.filter((item) => (!item.notification_visible));
  const user_info                                     = { user_login: (UserInfo && UserInfo.user !== undefined) ? UserInfo.user.user_login.substring(0, 9) : _lang_header_top_layout.header_btn.entrar }

  const [headerShow, setheaderShow]                   = useState(false);
  const [headerShowT, setheaderShowT]                 = useState(false);
  const [lastScrollTop, setLastScrollTop]             = useState(0);

  const [carrinho_open, set_carrinho_open]            = useState(false);
  const [language_open, set_language_open]            = useState(false);
  const [notifica_open, set_notifica_open]            = useState(false);
  const [user_menu_open, set_user_menu_open]          = useState(false);

  const [product_free, set_product_free]              = useState([]);
  const [product_vip, set_product_vip]                = useState([]);

  const { synchronizeAccount, backdrop_open }         = useSynchronizeAccount();

  const [open_pop_up_ads, set_open_pop_up_ads]        = useState(false);

  const pages_links = [
    {
      page_link: "/",
      page_name: _lang_header_top_layout.header_btn.home
    },
    {
      page_link: "/store",
      page_name: _lang_header_top_layout.header_btn.loja
    },
    {
      page_link: "/ui",
      page_name: _lang_header_top_layout.header_btn.mods
    },
    {
      page_link: (SocialLinks.link_discord !== undefined) ? SocialLinks.link_discord : "/",
      page_name: _lang_header_top_layout.header_btn.discord
    },
    {
      page_link: "/faq",
      page_name: _lang_header_top_layout.header_btn.faq
    },
  ];

  const generate_products_vip  = () => {
    const product_vip_formatting = UserInfo.product_vip.map((item) => {
      const generate_product_formatting = product_vip_informations(item, products_info);
      return (generate_product_formatting) && generate_product_formatting;
    });
    set_product_vip(product_vip_formatting);
  }

  const generate_products_free = () => {
    const product_free_formatting = UserInfo.product_free.map((item) => product_free_informations(item));
    set_product_free(product_free_formatting);
  }

  useEffect(() => {
    SOCKET_ANALYTICS.emit('acessar_pagina', {user: ((UserInfo && UserInfo.user !== undefined)) ? UserInfo.user : undefined, pagina_atual: current_page})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page]);

  useEffect(() => {
    if(UserInfo) {
      generate_products_free(); 
      generate_products_vip();

      const intervalId = setInterval(() => { 
        generate_products_free(); 
        generate_products_vip(); 
      }, 30000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Products, UserInfo]);

  useEffect(() => {setTimeout(() => { setheaderShow(false); setheaderShowT(false); }, 100);}, [location])

  useEffect(() => {
    function handleScroll() {
      const currentScrollTop  = document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop && lastScrollTop > 120) {
        setheaderShow(true);
        setheaderShowT(false);
      } else if(currentScrollTop < lastScrollTop && lastScrollTop > 120 && window.innerWidth > 510){
        setheaderShow(true);
        setheaderShowT(true);
      } else if(lastScrollTop < 120 ) {
        setheaderShow(false);
        setheaderShowT(false);
      }

      setLastScrollTop(currentScrollTop);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    const now = new Date();
    if(check_date_popup(PopUpsInfo.tiktok_live.date)) {
      PopUps.Update({ tiktok_live: { date: now, show: true } });
    }
    
    if (now.getHours() >= 17 && now.getHours() <= 20 && PopUpsInfo.tiktok_live.show) {
      setTimeout(() => set_open_pop_up_ads(true), 2000)
    }
  }, [PopUps, PopUpsInfo.tiktok_live.date, PopUpsInfo.tiktok_live.show]);

  const direct_page             = (page) => (page.includes("https")) ? window.open(page,'_blank') : history(page);
  
  const menu_handle             = (event, func, status) => (status) ? func(event.currentTarget) : func(false);
  
  const mobile_menu_function    = (status) => {
    if(status) {
      document.querySelector("body").classList.add("mobile-menu-visible");
      if(UserInfo) document.querySelector("#mobile-profile-icon").classList.add("mobile-remove-mode");
    } else {
      document.querySelector("body").classList.remove("mobile-menu-visible");
      setTimeout(() => (UserInfo) && document.querySelector("#mobile-profile-icon").classList.remove("mobile-remove-mode"), 200);
    }
  }

  const component_product_free  = product_free.map((item, index) => 
    <div key={index} className="mod-table">
      <div className="mod-table-header"><h6 className="title-mod-table">MOD:</h6><h6>{item.game}</h6></div>
      <div className="mod-table-header"><h6 className="title-mod-table">{_lang_header_top_layout.timer.timer_text}:</h6><h6>{item.access_end}</h6></div>
      <div className="mod-table-header"><h6 className="title-mod-table">{_lang_header_top_layout.timer.recharge}:</h6><h6>{item.access_recharge}</h6></div>
    </div>
  );

  const component_product_vip   = product_vip.map((item, index) => 
    <div key={index} className="mod-table mod-vip" onClick={() => {
      if(item.product_slug !== undefined) {
        history(`/ui/${item.product_slug}`);
        product_list_function(false);
      }
    }}>
      <div className="mod-table-header"><h6 className="title-mod-table">MOD:</h6><h6>{item.game}</h6></div>
      <div className="mod-table-header"><h6 className="title-mod-table">{_lang_header_top_layout.timer.timer_text}:</h6><h6>{item.access_end}</h6></div>
    </div>
  );
  
  return(
    <>
      <PopUpLive 
        open_pop_up_ads={open_pop_up_ads} 
        set_open_pop_up_ads={set_open_pop_up_ads}
        background_img={`/assets/img/popups/${_lang_base.lang_base}/liveon/liveon.gif`}
        redirect_product={""}
      />

      <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Menus 
        menu_handle={menu_handle}
        carrinho={{carrinho_open, set_carrinho_open}}
        language={{language_open, set_language_open}}
        notifica={{notifica_open, set_notifica_open}}
        user_menu={{user_menu_open, set_user_menu_open}}
      />

      <header>
        <div id="sticky-header" className={`tg-header__area transparent-header ${(headerShow) && "tg-sticky-menu" } ${(headerShowT) && "sticky-menu__show" }`}>
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div onClick={() => mobile_menu_function(true)} className="mobile-nav-toggler"><i className="fas fa-bars"></i></div>
                <div className="tgmenu__wrap">
                  <nav className="tgmenu__nav">
                    <div className="logo">
                      <div onClick={()=> history('/')}>
                        <img src={logo_gif} alt={_lang_base.web_site_name} />
                      </div>
                    </div>
                    <div className="tgmenu__navbar-wrap tgmenu__main-menu d-none d-xl-flex">
                      <ul className="navigation">
                        {pages_links.map((pagina, index) => <li key={`header${index}`}><div onClick={()=> direct_page(pagina.page_link)}>{pagina.page_name}</div></li>)}
                      </ul>
                    </div>
                    <div className="tgmenu__action d-none d-md-block">
                      <ul className="list-wrap">
                        
                        <li className="cart_menu">
                          <IconButton onClick={(event) => menu_handle(event, set_carrinho_open, true)} style={{color: "white"}} aria-label="cart">
                            <StyledBadge badgeContent={products_cart.length}>
                              <ShoppingCartIcon  />
                            </StyledBadge>
                          </IconButton>
                        </li>

                        <li>
                          <IconButton onClick={(event) => menu_handle(event, set_notifica_open, true)} style={{color: "white"}} aria-label="cart">
                            <StyledBadge badgeContent={user_notifications.length}>
                              <NotificationsIcon  />
                            </StyledBadge>
                          </IconButton>
                        </li>

                        <li>
                          <IconButton onClick={(event) => menu_handle(event, set_language_open, true)} style={{color: "white"}} aria-label="cart">
                            <StyledBadge badgeContent={0}>
                              <TranslateIcon />
                            </StyledBadge>
                          </IconButton>
                        </li>

                        <li className="cart_menu">
                          <FormGroup>
                            <FormControlLabel control={
                              <Switch 
                                checked={(UserConfig.hallowen_effect === undefined || UserConfig.hallowen_effect === true) ? true : false} 
                                onChange={() => {
                                  UserConfigBase.Update({ hallowen_effect: (UserConfig.hallowen_effect === undefined) ? false : !UserConfig.hallowen_effect })
                                }}
                                color="secondary" 
                              /> } label="Hallowen" 
                            />
                          </FormGroup>
                        </li>

                        <li className="header-btn">
                          <div className="tg-border-btn" onClick={(event) => (UserInfo) ? menu_handle(event, set_user_menu_open, true) : history('/login')}>
                            <i className="flaticon-edit"></i>{user_info.user_login}
                          </div>
                        </li>

                        <li onClick={() => product_list_function(true)} className="side-toggle-icon">
                          <span></span>
                          <span></span>
                          <span></span>
                        </li>

                      </ul>
                    </div>
                  </nav>
                </div>

                <div className="tgmobile__menu">
                  <nav className="tgmobile__menu-box">
                    <div onClick={() => mobile_menu_function(false)} className="close-btn"><i className="flaticon-swords-in-cross-arrangement"></i></div>
                    <div className="nav-logo"><img src={logo_gif} alt={_lang_base.web_site_name} /></div>
                    <div className="tgmobile__menu-outer">
                      <ul className="navigation">
                        {pages_links.map((pagina, index) => <li key={`mobile-header-${index}`}><div onClick={()=> direct_page(pagina.page_link)}>{pagina.page_name}</div></li>)}
                      </ul>
                    </div>
                    <div className="social-links">
                      <ul className="list-wrap"></ul>
                    </div>
                  </nav>
                </div>

                <div onClick={() => mobile_menu_function(false)} className="tgmobile__menu-backdrop"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="offCanvas__wrap">
          <div className="offCanvas__body">
            <div className="offCanvas__top">
              <div className="offCanvas__logo logo">
                <div onClick={()=> history('/')}>
                  <img src={logo_gif} alt={_lang_base.web_site_name} />
                </div>
              </div>
              <div onClick={() => product_list_function(false)} className="offCanvas__toggle">
                <i className="flaticon-swords-in-cross-arrangement"></i>
              </div>
            </div>
            <div className="offCanvas__content">
              <h2 className="title">{_lang_header_top_layout.offCanvas__wrap.title}<span>{_lang_header_top_layout.offCanvas__wrap.title_dois}</span></h2>
              <ul className="offCanvas__social list-wrap" id="table-mods">
                {component_product_free}
                {component_product_vip}
              </ul>
              <div className="buttons-header-second">
                { (UserInfo) ? 
                  <>
                    {(!UserInfo.user_discord) && (<div onClick={() => history('/link-discord')} className="mod-table-buttons">{_lang_header_top_layout.header_btn.vincular_discord}</div>)}
                    <div onClick={synchronizeAccount} className="mod-table-buttons">{_lang_header_top_layout.header_btn.atualizar_conta}</div>
                    <div onClick={EXIT_ACCOUNT} className="mod-table-buttons">{_lang_header_top_layout.header_btn.sair}</div>
                  </> : <h2 className='messages-nao-logado'>{_lang_header_top_layout.header_btn.user_is_not_logged_in}</h2>
                }
              </div>
            </div>
            <div className="offCanvas__copyright"><p>Copyright © 2024 - By <span>{_lang_base.web_site_name}</span></p></div>
          </div>
        </div>

        <div onClick={() => {product_list_function(false)}} className="offCanvas__overlay"></div>
      </header>

      { (UserConfig.hallowen_effect === undefined || UserConfig.hallowen_effect === true) && <Hallowen /> }
    </>
  )
}

export default HeaderTopLayout;
