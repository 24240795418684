
import { useState, useContext, useEffect }            from "react";
import { useNavigate }                                from "react-router-dom";
import { useSnackbar }                                from 'notistack';
import CircularProgress                               from '@mui/material/CircularProgress';

import LoginEmbed                                     from "../../Components/LoginEmbed/LoginEmbed";
import CreateSvg                                      from "../../Components/Common/CreateSvg";

import { gen_coin_type, fomate_date }                 from "../../Utils/StoreFunction";
import GETALLORDERS_AUTH                              from "../../Utils/AUTH/GETALLORDERS_AUTH";
import Context                                        from "../../Provider/Context";

import { _lang_my_orders, _lang_base }                from "../../Languages/languages";

function MyOrders() {
  const history                                       = useNavigate();
  const [loading, set_loading]                        = useState(true);
  const { enqueueSnackbar }                           = useSnackbar();

  const [orders_properties, set_orders_properties]    = useState([])
  const { UserInfoBase, UserCheckout }                = useContext(Context);
  const UserInfo                                      = UserInfoBase.Value;

  useEffect(() => {
    (async () => {
      try {
        set_loading(true);
        const RESULT_GET_ALL_ORDERS = await GETALLORDERS_AUTH(UserInfo.user);
        set_loading(false);
        if(RESULT_GET_ALL_ORDERS.success) {
          enqueueSnackbar(RESULT_GET_ALL_ORDERS.status_code_message, {variant: 'success'});
  
          delete RESULT_GET_ALL_ORDERS.status_code_message;
          delete RESULT_GET_ALL_ORDERS.success;
          delete RESULT_GET_ALL_ORDERS.status_code;
  
          set_orders_properties(RESULT_GET_ALL_ORDERS.orders);
          return;
        }
        enqueueSnackbar(RESULT_GET_ALL_ORDERS.status_code_message, {variant: 'error'});
      } catch (error) {
        set_loading(false);
        enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
      }
    })()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(!UserInfo || UserInfo.user === undefined) return <LoginEmbed /> ;
  
  const payment_the_order = (order) => {
    const current_time    = Math.floor(Date.now() / 1000);
    if(order.order_status === "cancelled")          return enqueueSnackbar(_lang_my_orders.order_cancelled, {variant: 'error'});
    else if(order.order_status === "completed")     return enqueueSnackbar(_lang_my_orders.order_completed, {variant: 'success'});
    else if(current_time > order.order_time_expire) return enqueueSnackbar(_lang_my_orders.timer_expired,   {variant: 'error'});
    UserCheckout.Update(order);
    history(`/checkout/${order.order_id}`);
  }

  const component_table_orders = (order, index) =>
    <div className="table-row micro" key={`order-${index}`}>
      <div className="table-column">
        <p className="table-text"><span className="light">{fomate_date(order.date_created.date)}</span></p>
      </div>
      <div className="table-column padded-left">
        <div onClick={() => payment_the_order(order)} className="table-link table-link-two"><span className="highlighted">#{order.order_id}</span></div>
      </div>
      <div className="table-column centered padded">
        <p className="table-title">{_lang_my_orders.payment_status[order.order_status]}</p>
      </div>
      <div className="table-column centered padded">
        <p className="table-title">{gen_coin_type(order.order_total, (
          order.order_currency !== undefined && 
          order.order_currency !== null      && 
          order.order_currency !== "") ? order.order_currency : "BRL")}</p>
      </div>
      <div className="table-column centered padded">
        <p className="table-title">{_lang_my_orders.payment_ids[(
          order.order_method !== undefined && 
          order.order_method !== null      && 
          order.order_method !== "") ? order.order_method : 0]}</p>
      </div>
      <div className="table-column padded-left">
        <div className="percentage-diff-icon-wrap positive" onClick={() => payment_the_order(order)} >
          <CreateSvg svgname="svg_plus_small" svgclassname="percentage-diff-icon icon-plus-small" />
        </div>
      </div>
    </div>

  return(
    <>
      {(loading) ? <div className="loading-orders"><CircularProgress color="inherit" size="5rem" /></div> : 
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">{_lang_my_orders.section_pretitle}</p>
              <h2 className="section-title">{_lang_my_orders.section_title}</h2>
            </div>
          </div>
          
          <div className="table-wrap" data-simplebar>
            <div className="table table-sales">
              <div className="table-header">
                <div className="table-header-column">
                  <p className="table-header-title">{_lang_my_orders.table_header_title_date}</p>
                </div>
                <div className="table-header-column padded-left">
                  <p className="table-header-title">{_lang_my_orders.table_header_title_order}</p>
                </div>
                <div className="table-header-column centered padded">
                  <p className="table-header-title">{_lang_my_orders.table_header_title_status}</p>
                </div>
                <div className="table-header-column centered padded">
                  <p className="table-header-title">{_lang_my_orders.table_header_title_total}</p>
                </div>
                <div className="table-header-column centered padded">
                  <p className="table-header-title">{_lang_my_orders.table_header_title_payment_methods}</p>
                </div>
                <div className="table-header-column padded-left"></div>
              </div>

              <div className="table-body same-color-rows">
                {orders_properties.map((item, index) => component_table_orders(item, index))}
              </div>
            </div>

          </div>
        </div>
      }
    </>
  )
}

export default MyOrders;
