const  _lang_chat = {
  title: "SUPPORT",
  sub_title: "SUPPORT",
  btn_title: "START CHAT",
  inp_title: "Type your question",

  bot_messages: {
    chat_finalizado: "Chat finished",
    aguarde_um_instante: "Please wait a moment, we're about to assist you...",
    seja_bem_vindo_de_volta: "Welcome back",
    tempo_medio: "Average response time is 5 minutes, if it takes longer, please contact us on our discord",
    message_late: `Good evening, We apologize for the inconvenience. Our support team operates normally from 8:00 am to 11:00 pm. Your ticket will remain open until one of our representatives contacts you.
    In the meantime, you can consult our help page (FAQ) for possible solutions.
    Thank you for your understanding.
    `,
    msg_digitando: "Typing"
  },
  messages: {
    chat_finalizado: "Chat successfully finished",
    nenhum_atendende: "No agents available to assist you right now...",
    precisa_estar_logado: "You need to be logged in",
    sincronizado: "Account synchronized successfully"
  }
}

module.exports =  { _lang_chat };
