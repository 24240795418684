const svgs = {
  svg_newsfeed:         (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M16,0H4C1.791,0,0,1.791,0,4v8c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V4C20,1.791,18.209,0,16,0zM18,12c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2V4c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V12z"/>
      <path d="M0,10v2h20v-2H0z M5,20h10v-2H5V20z"/>
    </svg>
  ),
  svg_marketplace:      (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M18,6h-0.5L16,0h-4v2h2.438l1,4H4.562l1-4H8V0H4L2.5,6H2C0.896,6,0,6.896,0,8v2c0,0.738,0.405,1.376,1,1.723v0.276C1,12,1,12,1,12l1,6c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2l1-6v-0.277c0.596-0.347,1-0.984,1-1.723V8C20,6.896,19.104,6,18,6z M2,8h16v2h-0.999H17H3H2.999H2V8z M16.027,17.671L16,17.834V18H4v-0.165l-0.027-0.164L3.028,12h13.945L16.027,17.671z"/>
    </svg>
  ),
  svg_return:           (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M19.12,13.852c-1.047,2.187-2.896,3.834-5.203,4.638C12.922,18.835,11.905,19,10.906,19c-3.751,0-7.273-2.313-8.59-6.006c-0.186-0.52,0.086-1.092,0.606-1.277c0.52-0.189,1.092,0.085,1.277,0.605c1.301,3.646,5.367,5.563,9.06,4.278c1.801-0.627,3.241-1.909,4.057-3.614c0.802-1.675,0.9-3.56,0.277-5.308c-1.3-3.644-5.363-5.562-9.06-4.278C7.541,3.746,6.623,4.316,5.877,5.052l-2.33,2.163h2.852c0.553,0,1,0.448,1,1c0,0.552-0.447,1-1,1H1c-0.007,0-0.013-0.004-0.019-0.004C0.901,9.21,0.826,9.185,0.749,9.164C0.699,9.151,0.646,9.149,0.601,9.13C0.555,9.109,0.518,9.071,0.474,9.044c-0.066-0.043-0.137-0.08-0.191-0.138C0.277,8.901,0.271,8.9,0.267,8.896C0.231,8.857,0.218,8.811,0.189,8.769C0.147,8.707,0.101,8.649,0.073,8.579C0.045,8.507,0.039,8.432,0.028,8.355C0.021,8.307,0,8.264,0,8.214V2.882c0-0.552,0.447-1,1-1c0.552,0,1,0.448,1,1v3.041l2.495-2.315c0.935-0.922,2.111-1.653,3.382-2.096c4.728-1.646,9.933,0.819,11.6,5.495C20.281,9.261,20.154,11.69,19.12,13.852z"/>
    </svg>
  ),
  svg_status:           (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M16,1H4C1.791,1,0,2.791,0,5v4v2v4c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V4C20,1.791,18.209,1,16,1z M18,15c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2v-4h16V15z M18,9H2V5c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V9z"/>
      <rect x="13" y="13" width="2" height="2"/>
      <rect x="9" y="13" width="2" height="2"/>
      <rect x="5" y="5" width="10" height="2"/>
      <rect x="5" y="13" width="2" height="2"/>
    </svg>
  ),
  svg_public:           (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M20,10c0-5.522-4.478-10-10-10C4.477,0,0,4.478,0,10c0,5.523,4.477,10,10,10
      c1.566,0,3.043-0.37,4.362-1.013l0.01,0.018c0.051-0.028,0.086-0.059,0.135-0.087C17.763,17.27,20,13.9,20,10z M16.155,15.108c-0.648-1.745-2.802-3.14-4.653-3.408c-0.751-0.109-0.829-0.433-0.858-0.555C10.497,10.532,11,9.499,11.679,9.021c1.482-1.044,4.529-0.504,6.252-0.025C17.972,9.325,18,9.659,18,10C18,11.942,17.307,13.723,16.155,15.108z M14.715,3.547c-0.012,0-0.022,0.003-0.034,0.003c-1.374,0-2.609-0.561-3.518-1.456C12.479,2.286,13.688,2.796,14.715,3.547z M2,10
      c0-2.277,0.956-4.328,2.483-5.785c1.26,0.272,2.644,0.845,2.87,1.36C7.373,5.62,7.425,5.74,7.222,6.051c-0.295,0.451-0.603,0.88-0.896,1.29c-1.389,1.937-2.963,4.131-0.647,6.169c0.537,0.472,0.805,1.243,0.624,1.793c-0.184,0.557-0.815,0.773-1.402,0.86C3.128,14.695,2,12.479,2,10z M7.065,17.437c0.671-0.479,0.993-1.077,1.135-1.507c0.432-1.308-0.063-2.92-1.201-3.922c-0.814-0.716-0.672-1.24,0.951-3.502c0.31-0.432,0.633-0.883,0.944-1.359c0.66-1.009,0.521-1.85,0.289-2.377c-0.428-0.972-1.522-1.606-2.57-2.01C7.251,2.46,7.934,2.244,8.65,2.122c1.236,2.047,3.469,3.428,6.03,3.428c0.618,0,1.206-0.106,1.776-0.259c0.339,0.463,0.631,0.961,0.865,1.492c-1.979-0.428-4.906-0.729-6.795,0.603c-1.195,0.84-2.204,2.651-1.828,4.224c0.268,1.122,1.185,1.876,2.516,2.068c1.409,0.205,2.963,1.352,3.137,2.314c0.028,0.156,0.1,0.618-0.781,1.158C12.495,17.689,11.285,18,10,18C8.963,18,7.975,17.796,7.065,17.437z"/>
    </svg>
  ),
  svg_small_arrow:      (classN) => (
    <svg className={classN} viewBox="0 0 6 8">
      <path d="M6,4L1.5,8L0,6.665L3,4L0,1.333L1.5,0l3,2.666l0,0L6,4z"/>
    </svg>
  ),
  svg_back_arrow:       (classN) => (
    <svg className={classN} viewBox="0 0 14 10">
      <path d="M14,6H3.364l2.644,2.75L4.806,10L1.202,6.25l0,0L0,5L4.806,0l1.201,1.25L3.364,4H14V6z"/>
    </svg>
  ),
  svg_grid:             (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M7,0H2C0.896,0,0,0.895,0,2v5c0,1.104,0.896,2,2,2h5c1.104,0,2-0.896,2-2V2C9,0.895,8.104,0,7,0z M7,7H2V2h5V7z M7,11H2c-1.104,0-2,0.896-2,1.999v5C0,19.104,0.896,20,2,20h5c1.104,0,2-0.896,2-2.001v-5C9,11.896,8.104,11,7,11z M7,17.999H2v-5h5V17.999z M18,0h-5c-1.104,0-2,0.895-2,2v5c0,1.104,0.896,2,2,2h5c1.104,0,2-0.896,2-2V2C20,0.895,19.104,0,18,0z M18,7h-5V2h5V7z M18,11h-5c-1.104,0-2,0.896-2,1.999v5C11,19.104,11.896,20,13,20h5c1.104,0,2-0.896,2-2.001v-5C20,11.896,19.104,11,18,11z M18,17.999h-5v-5h5V17.999z"/>
    </svg>
  ),
  svg_magnifying_glass: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M8,2c3.309,0,6,2.691,6,6s-2.691,6-6,6s-6-2.691-6-6S4.691,2,8,2 M8,0C3.582,0,0,3.582,0,8c0,4.418,3.582,8,8,8c4.418,0,8-3.582,8-8C16,3.582,12.418,0,8,0L8,0z"/>
      <path d="M19.171,15.168l-3.134-3.134c-0.309,0.613-0.679,1.19-1.113,1.714l2.833,2.834c0.323,0.324,0.323,0.851,0,1.175C17.545,17.969,17.298,18,17.17,18c-0.129,0-0.376-0.031-0.588-0.243l-2.834-2.833c-0.523,0.435-1.101,0.805-1.714,1.113l3.134,3.134C15.721,19.724,16.445,20,17.169,20c0.725,0,1.449-0.276,2.002-0.829C20.276,18.065,20.276,16.273,19.171,15.168z"/>
    </svg>
  ),
  svg_delete: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M10,20C4.477,20,0,15.523,0,10C0,4.478,4.477,0,10,0C15.522,0,20,4.478,20,10C20,15.523,15.522,20,10,20z M10,2c-4.418,0-8,3.582-8,8c0,4.418,3.582,8,8,8s8-3.582,8-8
      C18,5.582,14.418,2,10,2z M12.662,13.994L10,11.332l-2.662,2.662l-1.331-1.33l2.662-2.663L6.007,7.339l1.331-1.331L10,8.67l2.662-2.662l1.331,1.331l-2.662,2.662l2.662,2.663L12.662,13.994z"/>
    </svg>
  ),
  svg_thumbs_up: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M15.896,19.999c-0.01,0-0.021,0-0.03,0H2.826C1.268,19.999,0,18.743,0,17.201v-6.302c0-1.543,1.269-2.797,2.826-2.797h2.096l3.391-7.515C8.473,0.229,8.83,0,9.223,0c2.063,0,3.742,1.659,3.742,3.698l0.026,2.3h4.996c0.152-0.003-0.149-0.023,0,0c0.753,0.111,1.962,0.245,1.999,1c0.037,0.757-0.011,2.143,0,3.003l-1.295,7.624C18.483,18.982,17.284,19.999,15.896,19.999z M6.995,18.005h8.881c0.003,0,0.007,0,0.01,0c0.418,0,0.77-0.292,0.83-0.684l1.262-8.106c0.031-0.205-0.02-0.412-0.145-0.583c-0.132-0.178-0.621-0.601-0.845-0.634c-0.045-0.008,0.045-0.006,0,0h-4.996c-0.553,0-1-1.451-1-2.001l-0.026-2.3c0-0.733-0.477-1.36-1.144-1.6l-2.828,6.9V18.005z M2.997,10c-0.456,0-0.999,0.457-0.999,0.899v6.302c0,0.442,0.371,0.804,0.827,0.804h2.17V10H2.997z"/>         
    </svg>
  ),
  svg_comment: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M15,2c1.654,0,3,1.257,3,2.803v6.404c0,1.546-1.346,2.803-3,2.803H7.98H7.508l-0.422,0.211L2,16.764v-5.557V10V4.803C2,3.257,3.346,2,5,2H15 M15,0H5C2.238,0,0,2.15,0,4.803V10v1.207V20l7.98-3.99H15c2.762,0,5-2.15,5-4.803V4.803C20,2.15,17.762,0,15,0L15,0z"/>
      <g>
        <path d="M14,5H6C5.447,5,5,5.448,5,6c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1C15,5.448,14.553,5,14,5z M10,9H6c-0.553,0-1,0.447-1,1s0.447,1,1,1h4c0.553,0,1-0.447,1-1S10.553,9,10,9z"/>
      </g>        
    </svg>
  ),
  svg_profile: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M10,2c4.418,0,8,3.582,8,8c0,4.418-3.582,8-8,8c-4.418,0-8-3.582-8-8C2,5.582,5.582,2,10,2 M10,0C4.486,0,0,4.486,0,10c0,5.514,4.486,10,10,10c5.514,0,10-4.486,10-10C20,4.486,15.514,0,10,0L10,0z"/>
      <path d="M10,7c0.551,0,1,0.449,1,1s-0.449,1-1,1S9,8.551,9,8S9.449,7,10,7 M10,5C8.343,5,7,6.343,7,8c0,1.656,1.343,3,3,3c1.656,0,3-1.344,3-3C13,6.343,11.656,5,10,5L10,5z"/>
      <path d="M14.866,15.025C14.43,13.29,12.871,12,11,12H9c-1.871,0-3.43,1.29-3.866,3.025C5.678,15.553,6.308,15.988,7,16.317V16c0-1.104,0.897-2,2-2h2c1.103,0,2,0.896,2,2v0.317C13.692,15.988,14.322,15.553,14.866,15.025z"/>
    </svg>
  ),
  svg_minus_small: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <rect y="3" width="50" height="8"/>
    </svg>
  ),
  svg_plus_small: (classN) => (
    <svg className={classN} viewBox="0 0 8 8">
      <path d="M8,5H5v3H3V5H0V3h3V0h2v3h3V5z"/>
    </svg>
  ),
  svg_settings: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M10,7c-1.657,0-3,1.343-3,3c0,1.655,1.343,2.999,3,2.999c1.656,0,3-1.344,3-2.999C13,8.343,11.656,7,10,7zM10,11c-0.552,0-1-0.449-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C11,10.551,10.552,11,10,11z M17.363,12.638C18.817,12.638,20,11.453,20,10s-1.183-2.637-2.637-2.637h-0.135c-0.106,0-0.205-0.049-0.27-0.129 c-0.014-0.054-0.032-0.106-0.055-0.158c-0.059-0.132-0.031-0.283,0.111-0.429c0.498-0.499,0.772-1.161,0.772-1.865c0-0.704-0.274-1.367-0.773-1.865c-0.997-0.997-2.693-1.035-3.769,0.042c-0.102,0.1-0.24,0.135-0.397,0.066c-0.129-0.055-0.212-0.18-0.212-0.391C12.637,1.182,11.454,0,10,0S7.363,1.182,7.363,2.636v0.135c0,0.106-0.049,0.206-0.129,0.271C7.181,3.056,7.128,3.073,7.077,3.096c-0.128,0.058-0.264,0.052-0.43-0.113c-0.996-0.994-2.731-0.995-3.73,0.003c-1.026,1.028-1.025,2.701,0.044,3.77C3.062,6.858,3.089,7.01,3.007,7.2c-0.05,0.136-0.176,0.227-0.371,0.229C1.183,7.429,0,8.612,0,10.065c0,1.454,1.183,2.637,2.636,2.637h0.138c0.138,0,0.263,0.083,0.322,0.222c0.058,0.132,0.03,0.284-0.113,0.429c-0.497,0.498-0.771,1.161-0.771,1.865c0.001,0.705,0.276,1.367,0.773,1.863c0.996,0.996,2.689,1.038,3.771-0.042c0.101-0.099,0.142-0.173,0.443-0.046c0.136,0.049,0.226,0.176,0.229,0.37
      c0,1.454,1.183,2.637,2.637,2.637s2.637-1.183,2.637-2.637v-0.137c0-0.139,0.083-0.263,0.222-0.322c0.129-0.058,0.263-0.051,0.428,0.112c0.999,0.995,2.735,0.996,3.73-0.002c0.498-0.499,0.771-1.162,0.771-1.866c-0.001-0.704-0.275-1.366-0.813-1.903c-0.101-0.104-0.129-0.256-0.064-0.398C17.029,12.72,17.154,12.638,17.363,12.638zM15.666,14.7c0.121,0.12,0.188,0.279,0.188,0.449s-0.067,0.33-0.188,0.453c-0.239,0.241-0.605,0.295-0.956-0.058c-0.677-0.662-1.742-0.845-2.582-0.475c-0.864,0.369-1.423,1.214-1.425,2.153v0.14c0,0.352-0.286,0.636-0.637,0.636s-0.637-0.284-0.637-0.733c-0.023-0.97-0.627-1.815-1.479-2.125C7.649,15.007,7.329,14.939,7,14.939c-0.618,0-1.202,0.238-1.7,0.728c-0.24,0.24-0.66,0.241-0.901-0.002c-0.248-0.246-0.248-0.65,0.058-0.957c0.675-0.69,0.859-1.709,0.474-2.58c-0.368-0.864-1.214-1.424-2.154-1.426h-0.14C2.285,10.702,2,10.416,2,10.065
      s0.285-0.637,0.733-0.637c0.971-0.023,1.815-0.626,2.127-1.48c0.39-0.884,0.205-1.9-0.527-2.648C4.085,5.052,4.085,4.647,4.334,4.398c0.24-0.24,0.603-0.298,0.957,0.058C5.92,5.07,6.871,5.282,7.694,5c0.084-0.013,0.167-0.036,0.245-0.07c0.861-0.37,1.42-1.214,1.424-2.155v-0.14C9.363,2.285,9.649,2,10,2s0.637,0.285,0.637,0.712c0.002,0.937,0.561,1.782,1.414,2.148c0.865,0.38,1.884,0.222,2.649-0.527c0.241-0.24,0.656-0.243,0.901,0.001c0.12,0.12,0.187,0.279,0.187,0.449c0,0.169-0.066,0.331-0.242,0.507c-0.629,0.642-0.834,1.566-0.545,2.405
      c0.013,0.083,0.035,0.165,0.069,0.244c0.369,0.862,1.213,1.421,2.154,1.425h0.139C17.715,9.364,18,9.649,18,10c0,0.351-0.285,0.637-0.712,0.637c-0.938,0.002-1.783,0.561-2.146,1.411C14.749,12.933,14.933,13.951,15.666,14.7z"/>
    </svg>
  ),
  svg_group: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M10,2c1.654,0,3,1.346,3,3s-1.346,3-3,3S7,6.654,7,5S8.346,2,10,2 M10,0C7.238,0,5,2.238,5,5s2.238,5,5,5s5-2.238,5-5S12.762,0,10,0L10,0z"/>
      <path d="M3,20v-2c0-2.205,1.794-4,4-4h6c2.206,0,4,1.795,4,4v2h2v-2c0-3.313-2.687-6-6-6H7c-3.313,0-6,2.687-6,6v2H3z"/>
      <path d="M20,8.05c-1.13,0-2.05-0.919-2.05-2.05S18.87,3.95,20,3.95v-2c-2.236,0-4.05,1.813-4.05,4.05s1.813,4.05,4.05,4.05V8.05z"/>
      <path d="M0,8.05C1,8.05,2.05,7.13,2.05,6S1,3.95,0,3.95v-2c2,0,4.05,1.813,4.05,4.05S2,10.05,0,10.05V8.05z"/>
    </svg>
  ),
  svg_store: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M19.411,4.288l-1.894-3.301C17.174,0.378,16.537,0,15.855,0H4.047C3.37,0,2.734,0.376,2.39,0.981L0.492,4.288C-0.18,5.459-0.227,6.949,0.37,8.177C0.737,8.933,1.314,9.494,2,9.826V16c0,2.209,1.791,4,4,4h8c2.209,0,4-1.791,4-4V9.766c0.642-0.338,1.184-0.871,1.532-1.586C20.13,6.953,20.084,5.462,19.411,4.288z M2.227,5.289L4.047,2h11.732l1.896,3.289c0.343,0.598,0.365,1.388,0.059,2.018c-0.136,0.277-0.452,0.755-1.073,0.845c-0.567,0.088-1.203-0.159-1.624-0.674l-0.772-0.943L13.49,7.477c-0.725,0.883-2.046,0.883-2.767,0.001L9.95,6.532L9.176,7.477C8.452,8.361,7.13,8.359,6.41,7.478L5.636,6.531L4.862,7.478c-0.421,0.515-0.973,0.773-1.639,0.67C2.62,8.06,2.303,7.583,2.168,7.306C1.862,6.675,1.885,5.885,2.227,5.289zM4,10.11c0.582-0.086,1.148-0.295,1.636-0.641c1.263,0.9,3.046,0.9,4.313,0c1.265,0.9,3.046,0.901,4.315,0c0.518,0.368,1.113,0.592,1.736,0.665V14H4V10.11z M14,18H6c-1.103,0-2-0.897-2-2h12C16,17.103,15.103,18,14,18z"/>
    </svg>
  ),
  svg_events: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M16,2H4C1.791,2,0,3.791,0,6v10c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V6C20,3.791,18.209,2,16,2zM18,16c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2V6c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V16z"/>
      <path d="M0,8v2h20V8H0z M7,0H5v6h2V0z M15,0h-2v6h2V0z"/>
    </svg>
  ),
  svg_photos: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M16,0H4C1.791,0,0,1.791,0,4v12c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V4C20,1.791,18.209,0,16,0zM4,2h12c1.103,0,2,0.897,2,2v6.407L16.703,9.11c-0.75-0.751-1.734-1.126-2.718-1.126c-0.984,0-1.968,0.375-2.719,1.126l-2.98,2.98l-0.259-0.259c-0.563-0.563-1.301-0.844-2.039-0.844s-1.476,0.282-2.04,0.844L2,13.782V4C2,2.897,2.897,2,4,2z M16,18H4c-0.918,0-1.686-0.625-1.919-1.47l3.282-3.283c0.226-0.226,0.489-0.259,0.626-0.259c0.137,0,0.399,0.033,0.625,0.258l0.259,0.259l1.414,1.414L9.7,13.505l2.981-2.981c0.348-0.348,0.811-0.54,1.304-0.54c0.492,0,0.955,0.192,1.304,0.541L18,13.234V16C18,17.103,17.103,18,16,18z"/>
      <path d="M6,9c1.657,0,3-1.344,3-3c0-1.657-1.343-3-3-3S3,4.343,3,6C3,7.656,4.343,9,6,9z M6,5c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1S5,6.552,5,6C5,5.449,5.449,5,6,5z"/>
    </svg>
  ),
  svg_members: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M10,10c2.762,0,5-2.238,5-5c0-2.762-2.238-5-5-5S5,2.238,5,5C5,7.761,7.238,10,10,10z M10,2c1.654,0,3,1.346,3,3s-1.346,3-3,3S7,6.654,7,5S8.346,2,10,2z M13,12H7c-3.313,0-6,2.686-6,6v2h2v-2c0-2.205,1.794-4,4-4h6c2.206,0,4,1.795,4,4v2h2v-2C19,14.686,16.313,12,13,12z"/>
    </svg>
  ),
  svg_forums: (classN) => (
    <svg className={classN} viewBox="0 0 20 20">
      <path d="M16,0H4C1.791,0,0,1.592,0,3.556V6v2v4.444C0,14.408,1.791,16,4,16h2l4,4l4-4h2c2.209,0,4-1.592,4-3.556V8V6V3.556C20,1.592,18.209,0,16,0z M18,12.444C18,13.287,17.084,14,16,14h-2h-0.828l-0.586,0.586L10,17.172l-2.586-2.586L6.829,14H6H4c-1.084,0-2-0.713-2-1.556V8h16V12.444z M2,6V3.556C2,2.712,2.916,2,4,2h12c1.084,0,2,0.712,2,1.556V6H2z"/>
      <rect x="4" y="3" width="2" height="2"/>
      <rect x="10" y="3" width="2" height="2"/>
      <rect x="7" y="3" width="2" height="2"/>
    </svg>
  ),
};

function CreateSvg(props) {
  const svg_gerado = svgs[props.svgname](props.svgclassname);
  return (svg_gerado)
}

export default CreateSvg;
