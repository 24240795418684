const _lang_ui       = {
  category_list: {
    all_products: "All",
    favorites_products: "Favorites",
    recenty_products: "Recent",
    mods_vip: "Mods Vip",
    mods_free: "Mods Free",
    my_mods: "My Mods",
  },
  input_scan_mods: "Search Mods",
}

module.exports = { _lang_ui };
