import { AUTH }                                       from "../../Api/Conexao";
import { _lang_base }                                 from "../../Languages/languages";
import { verify_token_expired }                       from "../UserFunction";

const REQUESTPRODUCT_AUTH = async (current_user) => {
  try {
    if(current_user.demanded <= 10) throw new Error("410");
  } catch (error) {
    const status_code = _lang_base.requests[error.message];
    return { status_code_message: status_code, success: false };
  }

  try {
    const data = new URLSearchParams();
    data.append('token',  current_user.user.user_token_product);
    data.append('demanded', current_user.demanded);
    data.append('g-recaptcha-response', current_user.rpf);
    const _requestproduct_auth = await AUTH.post('/routes/requestproduct.php', data).then((response) => response.data);
    _requestproduct_auth.status_code_message = _lang_base.requests[_requestproduct_auth.status_code]
    return _requestproduct_auth;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default REQUESTPRODUCT_AUTH;
