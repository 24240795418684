import { SnackbarProvider }           from "notistack";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import Provider                       from "./Provider/Provider";
import Pages                          from "./Pages/Common/Pages";
import HeaderTopLayout                from "./Components/HeaderTopLayout/HeaderTopLayout";
import HeaderVerticalLayout           from "./Components/HeaderVerticalLayout/HeaderVerticalLayout";
import Chat                           from "./Components/Chat/Chat";
import WhatsappWidget                 from "./Components/WhatsappWidget/WhatsappWidget";
import Dialogs                        from "./Components/Dialogs/Dialogs";

import "react-medium-image-zoom/dist/styles.css";

/* IMPORT STYLES */
import "./assets/css/jquery-ui.css";
import "./assets/css/animate.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/normalize.min.css";
import "./assets/css/swiper-bundle.css";
import "./assets/css/slick.css";
import "./assets/css/spacing.css";
import "./assets/css/main.css";
import "./assets/css/ui.css";
import "./assets/css/popup.css";

/* NOVOS */
import "./assets/css/events/hallowen.css";
import "./assets/css/login.css";
import "./assets/css/discord.css";
import "./assets/css/chat.css";
import "./assets/css/profile/simplebar.css";
import "./assets/css/profile/styles.css";

const darkTheme = createTheme();

function Rotas() {
  return (
    <>
      <Provider>
        <Dialogs />
        <Chat />
        <WhatsappWidget />
        <HeaderVerticalLayout />
        <HeaderTopLayout />
        <Pages />
      </Provider>
    </>
  );
}

export default function App() {
  return (
  <SnackbarProvider maxSnack={3}>
    <ThemeProvider theme={darkTheme}><Rotas /></ThemeProvider>
  </SnackbarProvider>);
}
