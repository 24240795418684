import { useState, useEffect, useContext }            from "react";
import { useParams }                                  from "react-router-dom";

import Context                                        from "../../Provider/Context";
import HeaderHubProfile                               from "../../Components/HeaderHubProfile/HeaderHubProfile";
import CreateSvg                                      from "../../Components/Common/CreateSvg";
import LoginEmbed                                     from "../../Components/LoginEmbed/LoginEmbed";

import MyOrders                                       from "./MyOrders";
import ProfileInfo                                    from "./ProfileInfo";

import account_hub_icon                               from "../../assets/img/icons/profile/accounthub-icon.png";

import { _lang_hub, _lang_base }                      from "../../Languages/languages";

const findComponent_by_page_id = (components, page_id) => {
  for (const component of components) {
    const foundItem = component.sidebar_menu_body.find(
      (item) => item.sidebar_menu_link_id === page_id
    );
    if (foundItem) return component;
  }
  return null;
};

function Hub() {
  const { slug }                                          = useParams();
  const [page_id, set_page_id]                            = useState((slug === undefined) ? "profileinfo" : slug);
  const [header_id, set_header_id]                        = useState(1);
  const { UserInfoBase }                                  = useContext(Context);
  const UserInfo                                          = UserInfoBase.Value;

  const hub_components                                    = [
    {
      sidebar_menu_header_title: _lang_hub.hub_components.profile.sidebar_menu_header_title,
      sidebar_menu_header_text: _lang_hub.hub_components.profile.sidebar_menu_header_text,
      sidebar_menu_header_id: 1,
      sidebar_menu_header_icon: <CreateSvg svgname="svg_profile" svgclassname="sidebar-menu-header-icon icon-profile" />,
      sidebar_menu_body: [
        {
          sidebar_menu_link: _lang_hub.hub_components.profile.sidebar_menu_body.profile_info.sidebar_menu_link,
          sidebar_menu_link_id: "profileinfo",
          component_bar: <ProfileInfo />
        }
      ]
    },
    {
      sidebar_menu_header_title: _lang_hub.hub_components.store.sidebar_menu_header_title,
      sidebar_menu_header_text: _lang_hub.hub_components.store.sidebar_menu_header_text,
      sidebar_menu_header_id: 2,
      sidebar_menu_header_icon: <CreateSvg svgname="svg_store" svgclassname="sidebar-menu-header-icon icon-store" />,
      sidebar_menu_body: [
        {
          sidebar_menu_link: _lang_hub.hub_components.store.sidebar_menu_body.my_orders.sidebar_menu_link,
          sidebar_menu_link_id: "myorders",
          component_bar: <MyOrders />
        }
      ]
    }
  ]

  useEffect(() => {
    const component = findComponent_by_page_id(hub_components, slug);
    if (component) {
      set_header_id(component.sidebar_menu_header_id);
      set_page_id(slug);
    } else {
      set_page_id(1);
      set_page_id("profileinfo");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  
  if(!UserInfo || UserInfo.user === undefined) return <LoginEmbed /> ;

  const props = { page_id, set_page_id, hub_components, header_id, set_header_id };
  return (
    <>
      <div className="container profile">
        <div className="content-grid">
          <div className="section-banner">
            <img className="section-banner-icon" src={account_hub_icon} alt={_lang_base.web_site_name} />
            <p className="section-banner-title">{_lang_hub.section_banner_title}</p>
            <p className="section-banner-text">{_lang_hub.section_banner_text}</p>
          </div>

          <div className="grid grid-3-9">
            <HeaderHubProfile {...props} />
            {hub_components.map((item) => (item.sidebar_menu_body.map((sidebaritem) => (page_id === sidebaritem.sidebar_menu_link_id) && sidebaritem.component_bar)))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Hub;
