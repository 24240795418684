import { useContext }                                 from "react";
import Hexagon                                        from "react-hexagon";

import CreateSvg                                      from "../../Components/Common/CreateSvg";
import Context                                        from "../../Provider/Context";
import LoginEmbed                                     from "../../Components/LoginEmbed/LoginEmbed";

import base_icon                                      from "../../assets/img/badge/base_icon.png";
import base_backgoround                               from "../../assets/img/badge/base.png";

import { _lang_profile_info, _lang_base }             from "../../Languages/languages";

function ProfileInfo() {

  const { UserInfoBase }                              = useContext(Context);
  const UserInfo                                      = UserInfoBase.Value;

  if (!UserInfo || UserInfo.user === undefined) return <LoginEmbed />;

  const user_infos                                    = (UserInfo.user_info) ? {
    user_login:           UserInfo.user.user_login.substring(0, 18),
    user_phrase:          UserInfo.user_info.user_phrase.substring(0, 25),
    user_level:           UserInfo.user_info.user_level,
    user_avatar:          UserInfo.user_info.user_avatar,
    user_background:      UserInfo.user_info.user_background,
    user_posts:           UserInfo.user_info.user_posts,
    user_folowers:        UserInfo.user_info.user_folowers,
    user_visits:          UserInfo.user_info.user_visits,
    user_name:            "",
    user_email:           "",
    user_language:        "brl",
    user_badges:          [],
    user_bages_favorites: [],
    user_activities:      [],
  } : {
    user_login:           UserInfo.user.user_login.substring(0, 18),
    user_phrase:          "",
    user_level:           "0",
    user_avatar:          "/assets/img/avatar/perfil.jpg",
    user_background:      "/assets/img/avatar/background.jpg",
    user_posts:           "0",
    user_folowers:        "0",
    user_visits:          "0",
    user_name:            "",
    user_email:           "",
    user_language:        "eng",
    user_badges:          [],
    user_bages_favorites: [],
    user_activities:      [],
  };

  return(
    <>
      <div class="account-hub-content">
        <div class="section-header">
          <div class="section-header-info">
            <p class="section-pretitle">{_lang_profile_info.section_pretitle}</p>
            <h2 class="section-title">{_lang_profile_info.section_title}</h2>
          </div>
        </div>
        <div class="grid-column">
          <div class="grid grid-3-3-3 centered">
            <div class="user-preview small fixed-height">
              <figure 
                className="user-preview-cover liquid"
                style={{background: `url('${user_infos.user_background}') center center / cover no-repeat`}}
              ></figure>
              <div class="user-preview-info">
                <div class="user-short-description small">

                  <div class="user-short-description-avatar user-avatar">
                    <div className="user-avatar-border" bis_skin_checked="1">
                      <div className="hexagon-100-110" bis_skin_checked="1" style={{ width: '100px', height: '110px', position: 'relative' }}>
                        <Hexagon width="100" height="110" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_backgoround} />
                      </div>
                    </div>

                    <div className="user-avatar-progress" bis_skin_checked="1">
                      <div className="hexagon-progress-84-92" bis_skin_checked="1" style={{ width: '84px', height: '92px', position: 'relative' }}>
                        <Hexagon width="84" height="92" style={{ position: 'absolute', top: '0px', left: '0px' }} backgroundImage={user_infos.user_avatar} />
                      </div>
                    </div>

                    <div className="user-avatar-badge" bis_skin_checked="1">
                      <div className="user-avatar-badge-border" bis_skin_checked="1">
                        <div className="hexagon-28-32" bis_skin_checked="1" style={{ width: '28px', height: '32px', position: 'relative' }}>
                          <Hexagon width="28" height="32" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_icon} />
                        </div>
                      </div>
                      <p className="user-avatar-badge-text">{user_infos.user_level}</p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="upload-box">
              <CreateSvg svgname="svg_members" svgclassname="upload-box-icon icon-members" />
              <p class="upload-box-title">{_lang_profile_info.upload_box_title_avatar}</p>
              <p class="upload-box-text">{_lang_profile_info.upload_box_text_avatar}</p>
            </div>
            <div class="upload-box">
              <CreateSvg svgname="svg_photos" svgclassname="upload-box-icon icon-photos" />
              <p class="upload-box-title">{_lang_profile_info.upload_box_title_cover}</p>
              <p class="upload-box-text">{_lang_profile_info.upload_box_text_cover}</p>
            </div>
          </div>
          <div class="widget-box">
            <p class="widget-box-title">{_lang_profile_info.widget_box_title}</p>
            <div class="widget-box-content">
              <form class="form">
                <div class="form-row split">
                  <div class="form-item">
                    <div class="form-input small active">
                      <label for="profile-name">{_lang_profile_info.profile_name}</label>
                      <input 
                        type="text" 
                        id="profile-name" 
                        name="profile_name" 
                        value={user_infos.user_name}
                      />
                    </div>
                  </div>
                  <div class="form-item">
                    <div class="form-input small active">
                      <label for="profile-tagline">{_lang_profile_info.profile_tagline}</label>
                      <input 
                        type="text" 
                        id="profile-tagline" 
                        name="profile_tagline" 
                        value={user_infos.user_login}
                       />
                    </div>
                  </div>
                </div>
                <div class="form-row split">
                  <div class="form-item">
                    <div class="form-input small active">
                      <label for="profile-public-email">{_lang_profile_info.profile_public_email}</label>
                      <input 
                        type="text" 
                        id="profile-public-email" 
                        name="profile_public_email" 
                        value={user_infos.user_email} 
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="widget-box">
            <p class="widget-box-title">{_lang_profile_info.widget_box_title_badges}</p>
            <div class="widget-box-content">
              <div class="draggable-items">
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
                <div class="draggable-item empty"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default ProfileInfo;
