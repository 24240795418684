const _lang_hub   = {
  section_banner_title: "Central de contas",
  section_banner_text: "Informações de perfil, notificações, configurações e muito mais!",
  hub_components: {
    profile: {
      sidebar_menu_header_title: "Meu Perfil",
      sidebar_menu_header_text: "Mude seu avatar, capa, leia notificações e muito mais!",
      sidebar_menu_body: {
        profile_info: {
          sidebar_menu_link: "Informações do perfil",
        }
      }
    },
    store: {
      sidebar_menu_header_title: "Loja",
      sidebar_menu_header_text: "Revise sua conta, gerencie estatísticas de verificação de produtos e muito mais!",
      sidebar_menu_body: {
        my_orders: {
          sidebar_menu_link: "Meus Pedidos",
        }
      }
    },
  }
}

module.exports = { _lang_hub };
