import loadingIcon from "../../assets/img/icons/events/hallowen/morcego.gif";

const Hallowen = () => {
  return <>
    {
      Array.from({ length: 6 }, (_, i) => (
        <div key={`aranha${i}`} className={`spider_${i}`}>
          <div className='eye left'></div>
          <div className='eye right'></div>
          <span className='leg left'></span>
          <span className='leg left'></span>
          <span className='leg left'></span>
          <span className='leg left'></span>
          <span className='leg right'></span>
          <span className='leg right'></span>
          <span className='leg right'></span>
          <span className='leg right'></span>
        </div>
      ))
    }

  <div className="snowflakes">
    {
      Array.from({ length: 9 }, (_, i) => (
        <div key={`morcego${i}`} className="snowflake"><img alt="Morcegos" src={loadingIcon} /></div>
      ))
    }
  </div>
  </>
}

export default Hallowen;
