const _lang_login    = {
  title_login: "LOGIN",
  esqueceu_senha: "Esqueceu sua senha?",
  msg_bemvindo: "Seja bem-vindo",
  context_login: "Ainda não é usuário? Faça agora seu registro rápido e fácil",
  inputs_login: {
    login: "Email ou Usuário",
    senha: "Senha",
  },

  title_registrar: "CRIAR CONTA",
  msg_title: "Já possui cadastro?",
  context_registrar: "Faça, login agora mesmo com sua conta",
  inputs_registrar: {
    user_name: "Usuário",
    email: "Email",
    senha: "Senha",
    confirmar_senha: "Confirmar senha"
  },

  buttons_geral: {
    registrar_btn: "REGISTRAR",
    login_btn: "LOGIN",
  },


  login_error: "Usuário ou senha inválidos",
  login_success: "Logado com sucesso",

  registro_sucesso: "Usuário criado com sucesso",
  dados_invalidos: "Dados inválido",
  usuario_invalido: "Usuário ou senha inválidos",
  usuario_existe: "Usuário já existe",
  email_existe: "Email já existe",
  contactar_administrador: "Contate um administrador",

  registrar_errors: {
    confirmar_senha: "As senhas não são iguais",
    email_invalido: "Email inválido",
    senha_curta: "Senha muito curta",
    usuario_curto: "Usuário muito curto"
  }
}

module.exports = { _lang_login };
