const _lang_header_vertical_layout  = {
  pages_links: {
    page_mods: "Mods",
    page_my_acess: "My Accesses",
    page_recharge: "Recharge",
    page_active_key: "Activate Key",
    page_request_mod: "Request a Mod",
    page_download_loader: "Download Loader",
  },

  user_posts: "POSTS",
  user_likes: "LIKES",
  user_visits: "VISITS",
  user_logout: "Logout"
};

module.exports = { _lang_header_vertical_layout };
