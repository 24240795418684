import { useEffect, useState, useContext }              from "react";
import { useParams  }                                   from "react-router-dom";
import CreateSvg                                        from "../../Components/Common/CreateSvg";
import Context                                          from "../../Provider/Context";
import GET_CHANGE_LOGS                                  from "../../Utils/AUTH/GET_CHANGE_LOGS";
import ComponentNotFound                                from "../../Components/404/404";
import { SOCKET_ANALYTICS }                             from "../../Socket/Conexao";

import { _lang_change_logs, _lang_base }                from "../../Languages/languages";

const covert_text_html = (text) => {
  return text.replace(/\n/g, "<br>");
}

function ChangeLogs() {
  const [change_logs_history, set_change_logs_history]  = useState([]);
  const [change_logs_visits, set_change_logs_visits]    = useState(0);

  const { Products }                                    = useContext(Context);
  const { product }                                     = useParams();
  const product_info                                    = [...Products.free, ...Products.vip].find(item => item.product_slug === product);

  useEffect(() => {
    (async () => {
      if(product_info) {
        const change_logs = await GET_CHANGE_LOGS(product_info)
        if(!change_logs || 
          change_logs.change_logs === undefined || 
          change_logs.change_logs_visits.visits === undefined) return;
        set_change_logs_history(change_logs.change_logs);
        set_change_logs_visits(change_logs.change_logs_visits.visits);
        SOCKET_ANALYTICS.emit('change_logs_visits', {product: {product_id: product_info.product_id} });
      }
    })()
  }, [product_info])

  if(Products.vip === undefined || Products.free === undefined) return (<ComponentNotFound />);
  if(!product_info || !product_info.product_information || product_info.product_category_id !== 1) return (<ComponentNotFound />);

  const HtmlRenderer = ({ htmlContent }) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
  };

  const componnent_forum_post = change_logs_history.map((item, index) => 
    <div key={index} className="forum-post">
      <div className="forum-post-meta">
        <p className="forum-post-timestamp">{item.date_create}</p>
        <div className="forum-post-actions"></div>
      </div>
      <div className="forum-post-content forum-post-content-change-logs">
        <div className="forum-post-user forum-post-user-change-logs"></div>
        <div className="forum-post-info"><p className="forum-post-paragraph"><HtmlRenderer htmlContent={(_lang_base.lang_base === "pt_BR") ? covert_text_html(item.description_pt) : covert_text_html(item.description_en)} /></p></div>
      </div>
    </div>
  )

  return (
    <>
      <div className="container profile">
        <div className="content-grid">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">{_lang_change_logs.header_text}</p>
              <h2 className="section-title">{product_info.product_information.product_title}</h2>
            </div>
          </div>
          <div className="grid grid-9-3">
            <div className="forum-content">
              <div className="forum-post-header"></div>
              <div className="forum-post-list">
                {componnent_forum_post}
              </div>
            </div>
            
            <div className="forum-sidebar grid-column">
              <div className={`stats-decoration stats-decoration-status-product secondary ${product_info.product_information.product_status}-change-logs`}>
                <h5>{product_info.product_information.product_status}</h5>
              </div>

              <div className="stats-decoration secondary">
                <div className="stats-decoration-icon-wrap">
                  <CreateSvg svgname="svg_group" svgclassname="stats-decoration-icon icon-thumbs-up" />
                </div>
                <p className="stats-decoration-title">{change_logs_visits}</p>
                <p className="stats-decoration-text">{_lang_change_logs.visits_text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangeLogs;
