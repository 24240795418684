import { useContext }                                 from "react";
import { ThemeProvider, createTheme }                 from "@mui/material/styles";
import Dialog                                         from "@mui/material/Dialog";

import Context                                        from "../../Provider/Context";

import Recharge                                       from "../Recharge/Recharge";
import ActivateKey                                    from "../ActivateKey/ActivateKey";
import ReportProblem                                  from "../ReportProblem/ReportProblem";
import RequestProduct                                 from "../RequestProduct/RequestProduct";

const darkTheme                                       = createTheme({ palette: { mode: 'dark' } });

function Dialogs() {
  const { DialogBase }                                = useContext(Context);
  const dialog_status                                 = DialogBase.Value;

  return (
  <>
    <ThemeProvider theme={darkTheme}>
      <Dialog
        fullWidth={true}
        maxWidth={dialog_status.maxWidth}
        open={dialog_status.status}
        onClose={() => DialogBase.Update({ status: false })}
        disableScrollLock={true}
      >
        {(dialog_status.dialog_id === 0) && <Recharge set_dialog_menu_open={DialogBase.Update}  />}
        {(dialog_status.dialog_id === 1) && <ActivateKey set_dialog_menu_open={DialogBase.Update}  />}
        {(dialog_status.dialog_id === 3) && <ReportProblem set_dialog_menu_open={DialogBase.Update} product_id={dialog_status.product_id} />}
        {(dialog_status.dialog_id === 4) && <RequestProduct set_dialog_menu_open={DialogBase.Update}  />}
      </Dialog>
    </ThemeProvider>
  </>);
}

export default Dialogs;
