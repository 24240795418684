import { AUTH }                                       from "../../Api/Conexao";

const GET_CHANGE_LOGS = async (product) => {
  try {
    const data = new URLSearchParams();
    const request = await AUTH.get(`/json/changelogs/${product.product_id}.json?timestamp=${new Date().getTime()}`, data).then((response) => response.data);
    return request;
  } catch (error) {
    return false;
  }
}

export default GET_CHANGE_LOGS;
