const _lang_header_vertical_layout  = {
  pages_links: {
    page_mods: "Mods",
    page_my_acess: "Meus Acessos",
    page_recharge: "Recarga",
    page_active_key: "Ativar Chave",
    page_request_mod: "Solicitar um Mod",
    page_download_loader: "Baixar Loader",
  },

  user_posts: "POSTS",
  user_likes: "CURTIDAS",
  user_visits: "VISITAS",
  user_logout: "Sair"
}

module.exports = { _lang_header_vertical_layout };
