const _lang_profile_info    = {
  section_pretitle: "Meu Perfil",
  section_title: "Informações do perfil",

  upload_box_title_avatar: "Mudar Avatar",
  upload_box_text_avatar: "Tamanho mínimo de 110x110px",

  upload_box_title_cover: "Alterar capa",
  upload_box_text_cover: "Tamanho mínimo de 1184x300px",

  widget_box_title: "Sobre o seu perfil",
  profile_name: "Nome",
  profile_tagline: "Login",
  profile_public_email: "E-mail",
  widget_box_title_badges: "Gerenciar Emblemas",
}

module.exports = { _lang_profile_info };
