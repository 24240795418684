const _lang_profile_info = {
  section_pretitle: "My Profile",
  section_title: "Profile Information",

  upload_box_title_avatar: "Change Avatar",
  upload_box_text_avatar: "Minimum size of 110x110px",

  upload_box_title_cover: "Change Cover",
  upload_box_text_cover: "Minimum size of 1184x300px",

  widget_box_title: "About Your Profile",
  profile_name: "Name",
  profile_tagline: "Username",
  profile_public_email: "Email",
  widget_box_title_badges: "Manage Badges",
}

module.exports = { _lang_profile_info };
